import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Dashboard from 'src/components/Dashboard';
import Application from 'src/components/Application';
import AnywaysAPI from 'src/services/anyways';

const ContentWrapperStyle = { 
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	height: 'calc(100vh - 57px)',
	overflow: 'auto',
	justifyContent: 'center',
	alignItems: 'center',
	userSelect: 'none'
};

const ApplicationStyle = {
	bgcolor: '#2b2b2b',
	color: '#ffffff',
	display: 'flex',
	flexDirection: 'column',
	height: 'calc(100% - 32px)'
};

const SelectionWrapperStyle = {
	bgcolor: '#1a1a1a',
	p: 1,
	mb: 2,
	height: '100%',
	maxHeight: '76px'
};

const OBSApplication: React.FunctionComponent = () => (
	<>
		<Box sx={{ flexGrow: 1, p: 2 }}>
			<StreamPreview />
		</Box>
		<Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
			<Grid2 container columnSpacing={2}>
				<Scenes />
				<Sources />
				<Audio />
				<Controls />
			</Grid2>
		</Box>
	</>
);

const StreamPreview: React.FunctionComponent = () => (
	<Box sx={{ bgcolor: '#1a1a1a', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
		<Typography variant={'h6'} sx={{ color: '#888' }}>Preview</Typography>
	</Box>
);

const Scenes: React.FunctionComponent = () => (
	<Grid2 md={2.5}>
		<Typography variant={'subtitle1'} sx={{ mb: 2 }}>Scenes</Typography>
		<Box sx={SelectionWrapperStyle}>
			<Typography variant={'body2'}>Scene 1</Typography>
			<Typography variant={'body2'}>Scene 2</Typography>
			<Typography variant={'body2'}>Scene 3</Typography>
		</Box>
	</Grid2>
);

const Sources: React.FunctionComponent = () => (
	<Grid2 md={3.5}>
		<Typography variant={'subtitle1'} sx={{ mb: 2 }}>Sources</Typography>
		<Box sx={SelectionWrapperStyle}>
			<Typography variant={'body2'}>Image</Typography>
			<Typography variant={'body2'}>Text (GDI+)</Typography>
			<Typography variant={'body2'}>Video Capture Device</Typography>
		</Box>
	</Grid2>
);

const Audio: React.FunctionComponent = () => (
	<Grid2 md={3.5}>
		<Typography variant={'subtitle1'} sx={{ mb: 2 }}>Audio Mixer</Typography>
		<Box sx={SelectionWrapperStyle}></Box>
	</Grid2>
);

const Controls: React.FunctionComponent = () => (
	<Grid2 md={2.5}>
		<Typography variant={'subtitle1'} sx={{ mb: 2 }}>Controls</Typography>
		<Box sx={SelectionWrapperStyle}></Box>
	</Grid2>
);

const OBSSourceUrl: React.FunctionComponent = () => {
	const [url, setUrl] = React.useState<string>('loading...');

	React.useEffect(() => {
		const fetchUrl = async () => {
			const res = await AnywaysAPI.GetContext();
			setUrl('https://dashboard.anyways.tv/overlay/' + res.data._id);
		};

		fetchUrl();
	}, []);

	return (
		<Typography variant={'h6'} style={{padding: '1em'}}>OBS browser source URL: {url}</Typography>
	);
}

export const OBSPath = '/dashboard/obs';
export const OBS: React.FunctionComponent = () => (
	<Dashboard>
		<OBSSourceUrl/>
		<Box sx={ContentWrapperStyle}>
			<Application title={'OBS Studio'} sx={ApplicationStyle}>
				<OBSApplication />
			</Application>
		</Box>
	</Dashboard>
);

export default OBS;
