import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {Box} from "@mui/material";
import React from "react";

export function Thanks() {
    return <Grid2 direction={{ xs: 'row' }} spacing={{ xs: 4 }}>
        <Grid2 textAlign={'center'} xs={12} md={6}>
            <Box>
                <h2>Thanks!</h2>
                <p>Your donation will be processed soon.</p>
            </Box>
            <Box>
            </Box>
        </Grid2>
    </Grid2>
}