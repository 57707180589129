import React, {useEffect, useRef} from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import AnywaysAPI from "../../services/anyways";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import Config from "../../services/config";
import {loadStripe} from "@stripe/stripe-js";
import {useParams} from "react-router-dom";
import {PlayArrow} from "@mui/icons-material";

interface CurrencyOption {
    value: string;
    symbol: string;
    label: string;
}

const currencies: CurrencyOption[] = [
    {value: 'usd', symbol: '$', label: 'USD'},
    {value: 'eur', symbol: '€', label: 'EUR'},
    {value: 'gbp', symbol: '£', label: 'GBP'},
];

interface Voice {
    _id: string;
    name: string;
    sample: string;
}

export function Donate() {
    const [voices, setVoices] = React.useState<Voice[]>([
        {_id: 'LOADING', name: 'Loading available voices...', sample: ''},
    ]);
    const [selectedVoice, setSelectedVoice] = React.useState<string>(
        'LOADING',
    );

    const handleVoiceChange = (event: SelectChangeEvent<string>) => {
        setSelectedVoice(event.target.value);
    };

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const playVoiceSample = (sampleUrl: string) => {
        if (audioRef.current) {
            audioRef.current.src = sampleUrl;
            audioRef.current.play();
        }
    };

    const [messageLength, setMessageLength] = React.useState(0);
    const [submitted, setSubmitted] = React.useState(false);
    const [clientSecret, setClientSecret] = React.useState('');
    const [stripeAccount, setStripeAccount] = React.useState('');
    const [selectedCurrency, setSelectedCurrency] = React.useState<CurrencyOption>(currencies[0]);
    const {id} = useParams<{ id: string }>();
    const [streamerName, setStreamerName] = React.useState('your streamer');
    const [avatarUrl, setAvatarUrl] = React.useState('');

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitted(true);

        try {
            const form = e.target as HTMLFormElement;
            const formData = new FormData(form);

            let userID = '';
            if (id) {
                userID = id;
            }
            formData.set('recipient', userID)

            formData.set('currency', selectedCurrency.value);
            formData.set('voiceID', selectedVoice);
            const res = await AnywaysAPI.CreateStripeCheckoutSession(formData);

            setClientSecret(res.data.client_secret);
            setStripeAccount(res.data.stripe_account)
        } catch (error) {
            console.log(error)
        }
    };

    const checkMessageLength = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageLength(e.target.value.length);
        if (e.target.value.length > 250) {
            e.preventDefault();
            e.stopPropagation();
            e.target.style.color = 'red';
            console.log('Message too long')
        } else {
            e.target.style.color = '';
        }
    }

    const handleCurrencyInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value)) {
            e.target.value = value;
        } else {
            e.target.value = value.substring(0, value.length - 1);
        }
    }

    const handleCurrencyChange = (event: any) => {
        const currency = currencies.find(c => c.value === event.target.value);
        if (currency) {
            setSelectedCurrency(currency);
        }
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetchStreamerName = async () => {
            const response = await AnywaysAPI.GetUserByID(id);

            if (!response) {
                // Handle errors on the client side here
                const {error} = await response.error;
                throw (error);
            } else {
                const {avatarUrl, displayName} = await response.data;
                setStreamerName(displayName);
                setAvatarUrl(avatarUrl);
            }
        };

        const fetchVoices = async () => {
            const response = await AnywaysAPI.GetTTSVoices();
            if (!response) {
                // Handle errors on the client side here
                const {error} = await response.error;
                throw (error);
            } else {
                setVoices(response.data.voices);
                setSelectedVoice(response.data.voices[0]._id)
            }
        }

        fetchStreamerName()
        fetchVoices()
    }, [id]);

    return (
        <Grid2 direction={{xs: 'row'}} spacing={{xs: 4}}>
            <Grid2 textAlign={'center'} xs={12} md={6}>
                <Box>
                    <h2>Donate</h2>
                    <img src={avatarUrl} style={{
                        maxWidth: '10em',
                        maxHeight: '10em',
                        borderRadius: '50%',
                        objectFit: 'cover',
                    }}
                         alt={'Streamer avatar'}/>
                    <p>Support {streamerName}</p>
                </Box>
                <Box>
                    {!submitted && (
                        <Stack sx={StackStyle} component={'form'} onSubmit={onSubmit}>
                            <TextField label={'Display name'} name={'displayName'} defaultValue={'Anonymous'} required/>
                            <Box sx={{display: 'flex', gap: 1}}>
                                <FormControl sx={{width: '7em'}}>
                                    <InputLabel id="currency-select-label">Currency</InputLabel>
                                    <Select
                                        labelId="currency-select-label"
                                        value={selectedCurrency.value}
                                        label="Currency"
                                        onChange={handleCurrencyChange}
                                    >
                                        {currencies.map((currency) => (
                                            <MenuItem key={currency.value} value={currency.value}>
                                                {currency.symbol} {currency.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={'Amount'}
                                    name={'amount'}
                                    required
                                    aria-label={'Currency input'}
                                    inputMode={'decimal'}
                                    onChange={handleCurrencyInput}
                                    InputProps={{
                                        startAdornment: <InputAdornment
                                            position={'start'}>{selectedCurrency.symbol}</InputAdornment>
                                    }}
                                    defaultValue={'1.00'}
                                    sx={{width: '70%'}}
                                />
                            </Box>
                            <TextField label={'Message'} name={'message'} multiline rows={4}
                                       onChange={checkMessageLength}/>
                            <Box textAlign={'right'} marginRight={'1em'}>
                                <span>{250 - messageLength}</span>
                            </Box>
                            <FormControl fullWidth>
                                <InputLabel id="voice-select-label">Text-to-Speech Voice</InputLabel>
                                <Select
                                    labelId="voice-select-label"
                                    value={selectedVoice}
                                    label="Text-to-Speech Voice"
                                    onChange={handleVoiceChange}
                                    name="voiceId"
                                    onOpen={() => setIsDropdownOpen(true)}
                                    onClose={() => setIsDropdownOpen(false)}
                                >
                                    {voices.map((voice) => (
                                        <MenuItem key={voice._id} value={voice._id}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between"
                                                 width="100%">
                                                <span>{voice.name}</span>
                                                {(isDropdownOpen &&
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            playVoiceSample(voice.sample);
                                                        }}
                                                        size="small"
                                                    >
                                                        <PlayArrow/>
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box style={{marginLeft: 'auto', marginRight: 0}}>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                <Button variant={'contained'} type={'submit'}>Checkout</Button>
                            </Box>
                        </Stack>)
                    }
                    {submitted && clientSecret && (
                        <EmbeddedCheckoutProvider
                            stripe={loadStripe(
                                Config.stripe.publicKey,
                                {stripeAccount: stripeAccount},
                            )}
                            options={{clientSecret: clientSecret}}
                        >
                            <EmbeddedCheckout/>
                        </EmbeddedCheckoutProvider>
                    )}
                </Box>
            </Grid2>
            <audio ref={audioRef} style={{display: 'none'}}/>
        </Grid2>
    );
}

const StackStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '20em',
    marginLeft: 'auto',
    marginRight: 'auto',
}