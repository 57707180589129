import { createBrowserRouter, RouterProvider, RouteObject, Navigate } from 'react-router-dom';

import CheckAuth, { AuthMode } from 'src/components/CheckAuth';
import { Login, LoginPath } from 'src/pages/auth/login';
import Recovery, { RecoveryPath } from 'src/pages/auth/recovery';
import Logout, { LogoutPath } from 'src/pages/auth/logout';

import Revenue, { RevenuePath } from 'src/pages/dashboard/revenue';
import Activities, { ActivitiesPath } from 'src/pages/dashboard/activity';
import Overview, { OverviewPath } from 'src/pages/dashboard/overview';
import OBS, { OBSPath } from 'src/pages/dashboard/obs';
import Admin, { AdminPath } from 'src/pages/dashboard/admin';

import AccountSettings, { AccountSettingsPath } from 'src/pages/settings/account';
import SecuritySettings, { SecuritySettingsPath } from 'src/pages/settings/security';
import ManagersSettings, { ManagersSettingsPath } from 'src/pages/settings/managers';
import IntegrationsSettings, { IntegrationsSettingsPath } from 'src/pages/settings/integrations';

import {Donate} from "src/pages/donations/donate";

import NotFound from 'src/pages/404';
import {Thanks} from "./donations/thanks";
import AlertBox from "./donations/overlay";

export interface Route {
	path: string;
	element: JSX.Element;
	auth?: AuthMode;
}

export const Routes: Route[] = [
	{ path: '/', element: <Navigate to={OverviewPath} />, auth: 'required' },
	
	// Authentication
	{ path: LoginPath, element: <Login />, auth: 'noAuth' },
	{ path: RecoveryPath, element: <Recovery />, auth: 'noAuth' },
	{ path: LogoutPath, element: <Logout />, auth: 'required' },
	
	// Dashboard
	{ path: '/dashboard', element: <Navigate to={OverviewPath} />, auth: 'required' },
	{ path: OverviewPath, element: <Overview />, auth: 'required' },
	{ path: RevenuePath, element: <Revenue />, auth: 'required' },
	{ path: ActivitiesPath, element: <Activities />, auth: 'required' },
	{ path: OBSPath, element: <OBS />, auth: 'required' },
	{ path: AdminPath, element: <Admin />, auth: 'staff' },

	// Settings
	{ path: AccountSettingsPath, element: <AccountSettings />, auth: 'required' },
	{ path: SecuritySettingsPath, element: <SecuritySettings />, auth: 'required' },
	{ path: ManagersSettingsPath, element: <ManagersSettings />, auth: 'required' },
	{ path: IntegrationsSettingsPath, element: <IntegrationsSettings />, auth: 'required' },

	// Donations
	{ path: '/donate/:id', element: <Donate />, auth: 'optional'},
	{ path: '/donate/thanks', element: <Thanks />, auth: 'optional'},

	{ path: '/overlay/:id', element: <AlertBox />, auth: 'optional'},

	// Handle 404
	{ path: '*', element: <NotFound /> },
];

function getRoutes(routes: Route[]): RouteObject[] {
	let data: RouteObject[] = [];
	for(let route of routes) {
		route.element = <CheckAuth mode={route.auth ?? 'optional'}>{route.element}</CheckAuth>;
		data.push(route);
	}
	return data;
}

export const PageRouter: React.FunctionComponent = () => {
	const routes = getRoutes(Routes);
	return <RouterProvider router={createBrowserRouter(routes)} />
}

export default PageRouter;