import React from 'react';
import {Repeat} from "@mui/icons-material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography, Button, Select, InputLabel, MenuItem, SelectChangeEvent, Box
} from '@mui/material';

import AnywaysAPI from '../../services/anyways';

type donation = {
    _id: string;
    recipientID: string;
    amount: number;
    currency: string;
    donor: string;
    message: string;
    stripeID: string;
    ttsUrl: string;
    createdAt: string;
    updatedAt: string;
}

export const ActivitiesTable = () => {
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(false);
    const [donations, setDonations] = React.useState<donation[]>([]);

    React.useEffect(() => {
        const fetchDonations = async () => {
            const res = await AnywaysAPI.GetActivity(limit, offset);
            setDonations(res.data.donations);
            setHasMore(res.data.hasMore);
        }

        fetchDonations();
    }, [limit, offset]);

    const handleLimitChange = (event: SelectChangeEvent<{ value: number }>) => {
        setLimit(Number(event.target.value));
        setOffset(0); // Reset offset when changing limit
    };

    return (
        <div style={{
            paddingLeft: '2rem',
            paddingRight: '2rem',
        }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Donations
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="donations table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Donor</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Replay</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {donations.map((donation, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>{donation.amount}</TableCell>
                                <TableCell>{donation.currency}</TableCell>
                                <TableCell>{donation.donor}</TableCell>
                                <TableCell>{donation.message}</TableCell>

                                <TableCell>
                                    <Repeat
                                        onClick={() => AnywaysAPI.ReplayActivity(donation._id)}
                                        style={{cursor: 'pointer'}}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    margin: '1rem',
                }}>
                    <InputLabel id="limit-select-label"
                                sx={{
                                    position: 'static',
                                    transform: 'none',
                                }}
                    >Show</InputLabel>
                    <Select
                        labelId="limit-select-label"
                        id="limit-select"
                        value={{value: limit}}
                        label="Limit"
                        onChange={handleLimitChange}
                        renderValue={(value) => limit}
                    >
                        {[10, 25, 50, 75, 100].map((value) => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                    {offset > 0 ? <Button onClick={() => setOffset(offset - limit)}>Previous</Button> : null}
                    {hasMore ? <Button onClick={() => setOffset(offset + limit)}>Next</Button> : null}
                </Box>
            </TableContainer>
        </div>
    )
}
