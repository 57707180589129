import { useState, useEffect } from "react";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import Config from 'src/services/config';
import AnywaysAPI from 'src/services/anyways';

export const useStripeConnect = (connectedAccountId: string) => {
    const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | undefined>();

    useEffect(() => {
        if (connectedAccountId) {
            const fetchClientSecret = async () => {
                const response = await AnywaysAPI.CreateStripeAccountSession(connectedAccountId);

                if (!response) {
                    // Handle errors on the client side here
                    const { error } = await response.error;
                    throw (error);
                } else {
                    const { client_secret: clientSecret } = await response.data;
                    return clientSecret;
                }
            };

            setStripeConnectInstance(loadConnectAndInitialize({
                publishableKey: Config.stripe.publicKey,
                fetchClientSecret,
                appearance: {
                    overlays: "dialog",
                    variables: {
                        colorPrimary: "#ffffff",
                    },
                },
            }));
        }
    }, [connectedAccountId]);

    return stripeConnectInstance;
};

export default useStripeConnect;