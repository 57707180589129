import React, {useEffect, useState} from "react";
import { useStripeConnect } from "../../hooks/payments/useStripeConnect";
import {
    ConnectAccountOnboarding,
    ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { Button } from "@mui/material";
import AnywaysAPI from 'src/services/anyways';

export default function Onboarding() {
    const [accountCreatePending, setAccountCreatePending] = useState(true);
    const [onboardingExited, setOnboardingExited] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState<string>('');
    const stripeConnectInstance = useStripeConnect(connectedAccountId);

    // Handle the exit event
    const handleExit = () => {
        console.log('Onboarding exited');
        setOnboardingExited(true);
        AnywaysAPI.UpdateStripeAccount({isOnboarded: true})
    };

    // Fetch account info on component mount
    useEffect(() => {
        const fetchStripeAccount = async () => {
            try {
                const response = await AnywaysAPI.GetStripeAccount();
                // 404 means no account exists, anything else is an error
                if (response.status && response.status !== 404) {
                    setError(true);
                }

                if (response && response.data && response.data.account) {
                    setConnectedAccountId(response.data.account);
                    setOnboardingExited(response.data.isOnboarded)
                }
            } catch (err) {
                console.error('Error fetching Stripe account:', err);
                setError(true);
            }
            setAccountCreatePending(false);
        };

        fetchStripeAccount();
    }, []);

    return (
            <div className="content">
                {!accountCreatePending && !connectedAccountId && !error && (
                    <div>
                        <h2>Connect your account to Stripe to get started!</h2>
                        <Button color={'primary'} variant={'contained'}
                                onClick={async () => {
                                    setAccountCreatePending(true);
                                    setError(false);
                                    const response = await AnywaysAPI.CreateStripeAccount()

                                    if (response.status) {
                                        setError(true);
                                    }

                                    if (response) {
                                        setConnectedAccountId(response.data.account);
                                    }

                                    setAccountCreatePending(false);
                                }}
                        >
                            Sign up
                        </Button>
                    </div>
                )}
                {stripeConnectInstance && !onboardingExited && (
                    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                        <ConnectAccountOnboarding
                            onExit={handleExit}
                        />
                    </ConnectComponentsProvider>
                )}
                {error && <p className="error">Something went wrong, try again later!</p>}
                {(accountCreatePending || onboardingExited) && (
                    <div className="dev-callout">
                        {accountCreatePending && <p>Our hamsters are running as fast as they can for you...</p>}
                        {onboardingExited && <p>You are ready to start accepting payments!</p>}
                    </div>
                )}
            </div>
    );
}