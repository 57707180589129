import React from 'react';
import Dashboard, { DashboardContent } from 'src/components/Dashboard';
import {ActivitiesTable} from "../../components/activities/table";

export const ActivitiesPath = '/dashboard/activity';
export const Activities: React.FunctionComponent = () => (
	<Dashboard>
		<DashboardContent title={'Activity'} />
		<ActivitiesTable />
	</Dashboard>
);

export default Activities;
