import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useParams } from 'react-router-dom';
import AnywaysAPI from "../../services/anyways";

interface DonationMessage {
    amount: number;
    currency: string;
    donor: string;
    message: string;
    stripeID: string;
    ttsUrl: string;
    recipientID: string;
    createdAt: string;
    updatedAt: string;
}

const DonationAlert: React.FC<{ donation: DonationMessage }> = ({ donation }) => {
    return (
        <div style={{
            animation: 'slideIn 0.5s ease-out',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)',
            borderColor: '#8B5CF6',
            color: 'white',
            marginBottom: '1rem',
        }}>
            <h2 className="text-purple-300 font-bold" style={{
                color: '#C4B5FD',
                fontWeight: 'bold',
            }}>
                New Donation - {donation.amount} {donation.currency.toUpperCase()}
            </h2>
            <div className="mt-2" style={{
                marginTop: '0.5rem',
            }}>
                <p style={{
                    color: '#DDD6FE',
                    fontWeight: '600',
                }}>{donation.donor}</p>
                <p style={{
                    color: '#E5E7EB',
                    marginTop: '0.25rem',
                }}>{donation.message}</p>
            </div>
        </div>
    );
};

/* eslint react-hooks/exhaustive-deps: 0 */
const AlertBox = () => {
    const [currentDonation, setCurrentDonation] = useState<DonationMessage | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const { id } = useParams<{ id: string }>();
    const wsRef = useRef<WebSocket | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const donationQueue = useRef<DonationMessage[]>([]);
    const audioQueue = useRef<string[]>([]);
    const processingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isPlayingAudio = useRef<boolean>(false);

    const playNextAudio = useCallback(() => {
        if (audioQueue.current.length > 0 && audioRef.current && !isPlayingAudio.current) {
            const nextAudioUrl = audioQueue.current.shift();
            if (nextAudioUrl) {
                isPlayingAudio.current = true;
                audioRef.current.src = nextAudioUrl;
                audioRef.current.play().catch(error => {
                    console.error('Error playing audio:', error);
                    isPlayingAudio.current = false;
                    playNextAudio();
                });
            }
        } else if (audioQueue.current.length === 0) {
            processNextDonation();
        }
    }, []);

    const processNextDonation = useCallback(() => {
        if (processingTimeoutRef.current) {
            clearTimeout(processingTimeoutRef.current);
        }

        if (donationQueue.current.length > 0) {
            const nextDonation = donationQueue.current.shift();
            if (nextDonation) {
                setCurrentDonation(nextDonation);

                if (nextDonation.ttsUrl) {
                    audioQueue.current.push(nextDonation.ttsUrl);
                    playNextAudio();
                } else {
                    scheduleNextDonation(8000); // If no audio, show for 8 seconds
                }
            }
        } else {
            setCurrentDonation(null);
        }
    }, [playNextAudio]);

    const scheduleNextDonation = useCallback((delay: number) => {
        if (processingTimeoutRef.current) {
            clearTimeout(processingTimeoutRef.current);
        }
        processingTimeoutRef.current = setTimeout(() => {
            setCurrentDonation(null);
            processNextDonation();
        }, delay);
    }, [processNextDonation]);

    const connectWebSocket = useCallback(() => {
        if (wsRef.current && (wsRef.current.readyState === WebSocket.OPEN || wsRef.current.readyState === WebSocket.CONNECTING)) {
            console.log('WebSocket is already connected or connecting');
            return;
        }

        const userID = id || '';
        const newWs = AnywaysAPI.OverlayWebsocket(userID);

        newWs.onopen = () => {
            console.log('WebSocket connected');
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        newWs.onmessage = (event) => {
            const donation: DonationMessage = JSON.parse(event.data);
            donationQueue.current.push(donation);
            if (!currentDonation && !isPlayingAudio.current) {
                processNextDonation();
            }
        };

        newWs.onclose = (event) => {
            console.log('WebSocket disconnected. Attempting to reconnect...');
            wsRef.current = null;
            if (!reconnectTimeoutRef.current) {
                reconnectTimeoutRef.current = setTimeout(() => {
                    connectWebSocket();
                }, 1000);
            }
        };

        wsRef.current = newWs;
    }, [id, processNextDonation]);

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (processingTimeoutRef.current) {
                clearTimeout(processingTimeoutRef.current);
            }
        };
    }, [connectWebSocket]);

    useEffect(() => {
        const audio = new Audio();
        audioRef.current = audio;

        const handleAudioEnd = () => {
            isPlayingAudio.current = false;
            if (audioQueue.current.length > 0) {
                playNextAudio();
            } else {
                scheduleNextDonation(0);
            }
        };

        audio.addEventListener('ended', handleAudioEnd);

        return () => {
            audio.removeEventListener('ended', handleAudioEnd);
            audio.pause();
            audio.src = '';
        };
    }, [playNextAudio, scheduleNextDonation]);

    return (
        <div style={{
            position: 'fixed',
            inset: 0,
            pointerEvents: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '1rem',
            overflow: 'hidden'
        }}>
            {currentDonation && <DonationAlert donation={currentDonation} />}
        </div>
    );
};

// Add the slideIn animation
const styles = `
    @keyframes slideIn {
        from {
            transform: translateX(100%);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }
    }
`;

// Add the styles to the document
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default AlertBox;