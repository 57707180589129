const protocol = window.location.protocol;
const domain = window.location.hostname === 'localhost' ? 'localhost' : 'anyways.tv';

interface EnvironmentMapping {
	env: Record<string, Config>;
}

interface Config {
	cookieHost: string;
	api: {
		baseUrl: string;
	};
	stripe: {
		publicKey: string;
	};
}

const config: EnvironmentMapping = {
	env: {
		development: {
			cookieHost: 'localhost',
			api: {
				baseUrl: `${protocol}//localhost:8080`
			},
			stripe: {
				publicKey: 'pk_test_51PhZSdFoTajlkzFdtZ1Fk5U9bdgR8s4QhICYVD933Qig1sRpE1aegJl3tE5w3aud3ibUr9uc7947eN9sg66ssVEU00jbSFuJVB'
			}
		},
		production: {
			cookieHost: `.${domain}`,
			api: {
				baseUrl: `${protocol}//api.${domain}`
			},
			stripe: {
				// Due to lack of skill, I have hardcoded the stripe public key. -bread
				publicKey: 'pk_test_51PhZSdFoTajlkzFdtZ1Fk5U9bdgR8s4QhICYVD933Qig1sRpE1aegJl3tE5w3aud3ibUr9uc7947eN9sg66ssVEU00jbSFuJVB'
			}
		}
	}
};

const getConfig = () => {
	const environment = domain === 'localhost' ? 'development' : 'production';
	return config.env[environment];
};

export default getConfig();
